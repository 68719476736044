<!--
 * @Author: laizerong 1369743864@qq.com
 * @Date: 2022-07-15 09:49:52
 * @LastEditors: laizerong 1369743864@qq.com
 * @LastEditTime: 2022-07-27 17:23:19
 * @FilePath: \nuxtjs-based:\project\Aigo.OfficialWebsite.AdminWeb\src\views\classAdmin\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="class-admin">
    <el-form ref="classAdminForm" :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item prop="title">
        <el-input v-model="formData.title" clearable placeholder="类目名称"></el-input>
      </el-form-item>
      <!-- <el-form-item prop="status">
        <el-select v-model="formData.status" clearable placeholder="是否启用">
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table-btn">
      <el-button type="success" @click="synchronize">同步OAS产品组数据</el-button>
    </div>
    <el-table
      ref="classAdminTable"
      :data="tableData.list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="goodsClassifyId"
      border
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      @select="select"
      @select-all="selectAll">
      <el-table-column type="selection" width="55" align="center" :reserve-selection="true"></el-table-column>
      <el-table-column prop="level" label="目录层级" align="center"></el-table-column>
      <el-table-column prop="title" label="类目名称" align="center"></el-table-column>
      <el-table-column prop="sort" label="排序" width="130" align="center"></el-table-column>
      <el-table-column prop="backgroundImage" width="180" label="图片" align="center">
        <template slot-scope="{row}">
          <el-image v-if="row.backgroundImage" :src="row.backgroundImage"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="brandCode" label="品牌" align="center"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间" align="center">
        <template slot-scope="{row}">
          <span>{{row.updateTime | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateByName" label="操作人" align="center"></el-table-column>
      <el-table-column prop="goodsClassifyId" label="操作" width="180" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="edit(row)">编辑</el-button>
          <el-button type="text" @click="configParams(row)">配置参数</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        style="margin-top: 10px"
        background
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-size="formData.limit"
        layout="total, prev, pager, next, jumper"
        :total="tableData.total"
      ></el-pagination>
    </div>
    <!-- 编辑 -->
    <el-dialog
      title="编辑类目"
      :visible.sync="editDialogShow"
      width="50%"
      v-if="editDialogShow"
    >
      <div class="dialog-box">
        <edit :goodsClassifyId="row.goodsClassifyId" :row="row" @cancel="editCancel" @confirm="editConfirm"></edit>
      </div>
    </el-dialog>
    <!-- 配置参数 -->
    <el-dialog
      title="配置参数"
      :visible.sync="configDialogShow"
      width="50%"
      v-if="configDialogShow"
    >
      <div class="dialog-box">
        <config-params :goodsClassifyId="row.goodsClassifyId"></config-params>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import configParams from '@/components/page/classAdmin/configParams.vue';
import edit from '@/components/page/classAdmin/edit.vue';
import { formatDate } from '@/filters/index.js'
export default {
  name: 'ClassAdmin',
  components: {
    configParams,
    edit
  },
  data() {
    return {
      editDialogShow: false,
      configDialogShow: false,
      formData: {
        title: '',
        // status: '',
        page: 1,
        limit: 10
      },
      fileList: [],
      tableData: {
        total: 0,
        list: [],
      },
      row: {}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      var _this = this;
      this.$store.dispatch('getGoodsList', this.formData).then((res) => {
        console.log(res)
        _this.tableData = res
      });
    },
    handleCurrentChange(page) {
      this.formData.page = page;
      this.getList()
    },
    // 查询
    search() {
      this.getList()
    },
    // 重置
    reset() {
      this.$nextTick(() => {
        this.$refs.classAdminForm.resetFields()
        this.getList()
      })
      
    },
    // 编辑
    edit(row) {
      this.row = row
      this.editDialogShow = true
    },
    // 编辑成功
    editConfirm() {
      this.editDialogShow = false
      this.formData.page = 1
      this.getList()
    },
    // 取消
    editCancel() {
      this.editDialogShow = false
    },
    // 配置参数
    configParams(row) {
      this.row = row
      this.configDialogShow = true
    },
    // 表格勾选
    select(selection,row) {
      if(selection.some(e => { return row.goodsClassifyId === e.goodsClassifyId })) {
        this.recursion(row,true)
      } else {
        this.recursion(row,false)
      }
    },
    // 表格全选
    selectAll(selection) {
      // 第一级选中，则子类全都选中
      const isSelect = selection.some(item => {
        const tableDataIds = this.tableData.list.map(v => v.goodsClassifyId)
        return tableDataIds.includes(item.goodsClassifyId)
      })
      // 第一级只要不在selection里面就是全不选
      const isCancel = !this.tableData.list.some(item => {
        const selectIds = selection.map(v => v.goodsClassifyId)
        return selectIds.includes(item.goodsClassifyId)
      })
      if(isSelect) {
        selection.map(row => {
          this.recursion(row,true)
        })
      }
      if(isCancel) {
        this.tableData.list.map(row => {
          this.recursion(row,false)
        })
      }
    },
    // 同步OAS产品组数据
    synchronize() {
      console.log(this.$refs.classAdminTable.selection)
      let ids = []
      this.$refs.classAdminTable.selection.map(val => {
        ids.push(val.goodsClassifyId)
      })
      this.getList()
      this.$store.dispatch('classifySync',ids).then(res => {
        console.log(res)
        if(res == '') {
          this.$message.success('同步OAS产品组数据成功')
          this.$refs.classAdminTable.clearSelection()
          this.getList()
        }
      })
    },
    /**
     * @param {object} row 当前选中的行数据
     * @param {boolean} isChecked 是否选中
     */
    recursion(row, isChecked) {
      if(row.children) {
        row.children.map(v => {
          this.$refs.classAdminTable.toggleRowSelection(v,isChecked)
          if(v.children) {
            this.recursion(v)
          }
        })
      }
    },
    
  }
}
</script>
<style scoped>
.class-admin {
  padding-top: 20px;
}
.el-form {
  padding-left: 20px;
}
.table-btn {
  padding: 0 0 20px 20px;
}
.dialog-box {
  
}

</style>